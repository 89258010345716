// API
import { STREAMS } from 'constants/routes';
// Types
import { UserRole } from 'api/graphql-global-types';
// Hooks
import { useAthleteRelatedStreams } from 'hooks';
// Common
import Section from 'components/common3/Section/Section';
// Components
import RelatedStreams from './RelatedStreams/RelatedStreams';
import LiveStream from './LiveStream/LiveStream';

type StreamsProps = {
  storeId: string;
  storeSlug: string | null;
  storeRole: UserRole;
  className?: string;
  link?: string;
  hasActiveStream?: boolean;
};

const Streams = ({
  storeId,
  storeSlug,
  storeRole,
  className,
  link,
  hasActiveStream = false,
}: StreamsProps) => {
  const { streams } = useAthleteRelatedStreams({ storeId });

  const [featuredStream, ...relatedStreams] = streams;

  const seeAllLink = link
    ? link
    : storeSlug && storeRole === UserRole.Athlete
    ? `${STREAMS}?athlete=${storeSlug}`
    : storeId && storeRole === UserRole.Organization
    ? `${STREAMS}?organizationIds=${storeId}`
    : storeRole === UserRole.ContentCreator
    ? `${STREAMS}?contentCreatorIds=${storeId}`
    : STREAMS;

  if (!streams.length) {
    return null;
  }

  return (
    <>
      {hasActiveStream ? <LiveStream stream={featuredStream} /> : null}
      <Section
        title="Other Streams You Might Like"
        seeAllLink={seeAllLink}
        seeAllLinkTitle="See Channel"
        className={className}
      >
        <RelatedStreams streams={hasActiveStream ? relatedStreams : streams} />
      </Section>
    </>
  );
};

export default Streams;
