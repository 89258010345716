import cn from 'classnames';
// Types
import {
  GetAthleteStreams_liveAndUpcomingStreams_entities,
  GetAthleteStreams_pastStreams_entities,
} from 'api/streams/types/GetAthleteStreams';
// Constants
import { ATHLETES } from 'constants/routes';
// Helpers
import { getStreamStatus } from 'helpers/streams';
import { computeOrderStreamPath } from 'helpers/routes';
// Ui
import ContentCard from 'ui3/Card/ContentCard';
// Components
import CarouselSlider from 'components/common3/CarouselSlider/CarouselSlider';
// Styles
import styles from './RelatedStreams.module.scss';

type RelatedStreamsProps = {
  streams:
    | GetAthleteStreams_liveAndUpcomingStreams_entities[]
    | GetAthleteStreams_pastStreams_entities[];
  className?: string;
};

const RelatedStreams = ({ streams, className }: RelatedStreamsProps) => {
  if (!streams?.length) {
    return null;
  }

  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.rootCarousel}>
        <CarouselSlider settings={{ infinite: streams.length > 4 }}>
          {streams.map((stream) => (
            <ContentCard
              key={stream.id}
              type={stream.type}
              status={getStreamStatus(
                stream.repeatsEveryEnum,
                stream.isHidden,
                stream.streamStatus
              )}
              imageUrl={stream?.imageURL || ''}
              linkUrl={computeOrderStreamPath(stream)}
              dateTime={stream?.scheduleDate || ''}
              tzCode={stream?.timeZone?.tzCode || null}
              title={stream?.name || ''}
              price={
                stream?.price === 0 ? 'free' : `$${stream?.price.toString()}`
              }
              user={stream.store?.storeDetails?.storeName || ''}
              userUrl={stream.store?.slug ? `/${stream.store?.slug}` : ATHLETES}
              isPlay
            />
          ))}
        </CarouselSlider>
      </div>
    </div>
  );
};

export default RelatedStreams;
