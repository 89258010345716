import React from 'react';
import { useRouter } from 'next/router';
// Types
import {
  GetAthleteStreams_liveAndUpcomingStreams_entities,
  GetAthleteStreams_pastStreams_entities,
} from 'api/streams/types/GetAthleteStreams';
// Helpers
import { getStreamLink, getStreamDateV2 } from 'helpers/streams';
// Ui
import LinkButton from 'ui3/LinkButton/LinkButton';
import Text from 'ui3/Text/Text';
import EllipsisText from 'ui3/EllipsisText/EllipsisText';
import ContentCard from 'ui3/Card/ContentCard';
// Styles
import styles from './LiveStream.module.scss';

type Stream =
  | GetAthleteStreams_liveAndUpcomingStreams_entities
  | GetAthleteStreams_pastStreams_entities;

type LiveStreamProps = {
  stream?: Stream;
  loading?: boolean;
  onLinkClick?: (
    data: Stream,
    e: React.SyntheticEvent<HTMLAnchorElement>
  ) => void;
};

const LiveStream = ({ stream }: LiveStreamProps) => {
  const { push } = useRouter();

  if (!stream) {
    return null;
  }

  const streamLink = getStreamLink(stream);
  const streamDate = getStreamDateV2(stream?.scheduleDate, stream?.timeZone);

  const handleReadMoreClick = () => {
    push(streamLink);
  };

  const isExternalLink = streamLink?.startsWith('http');

  return (
    <section className={styles.root}>
      <div className={styles.header}>
        <Text variant="h5">Live Now</Text>
      </div>
      <div className={styles.mainBlock}>
        <ContentCard
          className={styles.contentCard}
          type={stream.type}
          status={stream.streamStatus}
          imageUrl={stream?.imageURL || ''}
          price={stream?.price === 0 ? 'free' : `$${stream?.price.toString()}`}
          size="large"
        />

        <div className={styles.body}>
          <Text variant="h3" className={styles.title}>
            {stream.name}
          </Text>
          <Text
            variant="body2Regular"
            color="lights-low"
            className={styles.date}
          >
            {streamDate}
          </Text>
          <EllipsisText
            text={stream.description}
            className={styles.description}
            maxLine={{ xs: 2, ml: 3 }}
            isGold
            customMoreText="Read More"
            onReadMoreClick={handleReadMoreClick}
          />
          <LinkButton
            className={styles.linkButton}
            variant="secondary"
            icon="playSolid"
            iconPosition="right"
            iconSize={16}
            link={streamLink}
            target={isExternalLink ? '_blank' : undefined}
            rel={isExternalLink ? 'noopener' : undefined}
          >
            Watch Stream
          </LinkButton>
        </div>
      </div>
    </section>
  );
};

export default LiveStream;
