import cn from 'classnames';
// Types
import { IconNames } from 'ui3/MillionsIcon/MillionsIcon';
// Ui3
import LinkButton from 'ui3/LinkButton/LinkButton';
import Text from 'ui3/Text/Text';
// Styles
import styles from './Section.module.scss';

type AllOrNone<T> = T | { [k in keyof T]?: never };

type LinkData = AllOrNone<{
  seeAllLink: string;
  seeAllLinkTitle: string;
}>;

type SectionProps = {
  title: string;
  backgroundTitle?: string;
  theme?: 'white' | 'black';
  className?: string;
  icon?: IconNames | 'none';
  subVariant?: 'text' | 'outlined' | 'none';
} & LinkData;

const Section: React.FC<SectionProps> = ({
  title,
  seeAllLink,
  seeAllLinkTitle,
  className,
  children,
  icon = 'chevronRight',
  subVariant = 'text',
}) => {
  const isExternalLink = seeAllLink?.startsWith('http');

  return (
    <section className={cn(styles.root, className)}>
      {(title || seeAllLink) && (
        <div className={styles.header}>
          {title && (
            <Text variant="h5" color="lights-high">
              {title}
            </Text>
          )}

          {seeAllLink && (
            <LinkButton
              variant="secondary"
              link={seeAllLink}
              iconPosition="right"
              iconSize={16}
              className={subVariant !== 'none' ? styles.linkButton : ''}
              target={isExternalLink ? '_blank' : undefined}
              rel={isExternalLink ? 'noopener' : undefined}
              {...(icon !== 'none' && { icon })}
              {...(subVariant !== 'none' ? { subVariant } : { size: 'medium' })}
            >
              {seeAllLinkTitle}
            </LinkButton>
          )}
        </div>
      )}

      <div
        className={cn(styles.body, {
          [styles.noHeader]: !title,
        })}
      >
        {children}
      </div>
    </section>
  );
};

export default Section;
